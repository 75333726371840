<template>
  <v-container class="text-center pt-15">
    <v-row justify="center" class="mt-5 mb-5">
      <v-col cols="12">
        <v-card>
          <v-card-title class="d-flex align-center justify-center pa-3">
            <v-row justify="center" no-gutters align="center">
              <v-col cols="12" md="4" class="text-center" v-show="$vuetify.breakpoint.smAndUp">
                <v-card-title>Filtrar por:</v-card-title>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedPeriod"
                  :items="periods"
                  label="Tipo de periodo"
                  prepend-icon="mdi-calendar"
                  dense
                  class="mx-2 mt-5"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="selectedYear"
                  :items="years"
                  label="Año"
                  prepend-icon="mdi-calendar"
                  dense
                  class="mx-2 mt-5"
                  :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '190px' : '' }"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search..." outlined dense class="mb-4"></v-text-field> -->
    <v-data-table :headers="headers" :items="items" class="elevation-1" item-value="id" :items-per-page="10">
      <template v-slot:top class="searchBar">
        <v-row class="pt-2 pl-15">
          <v-col cols="3">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search..." outlined dense></v-text-field>
          </v-col>
        </v-row>
      </template>

      <!-- <template v-slot:[`item.status`]="{ item }">
        <v-select v-model="item.status" :items="statusOptions" label="Estatus" outlined dense></v-select>
      </template> -->
      <template v-slot:[`item.status`]="{ item }">
        <v-menu v-model="item.menu" close-on-content-click nudge-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" :color="getStatusColor(item.status)" outlined small class="status-chip" text-center>
              {{ item.status }}
            </v-chip>
          </template>

          <v-list>
            <v-list-item v-for="status in statusOptions" :key="status" @click="item.status = status">
              <v-list-item-title>{{ status }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: '#', value: 'id', width: '5%' },
        { text: 'Ubicación', value: 'location' },
        { text: 'Nombre', value: 'name' },
        { text: 'Fuente de emisión', value: 'source' },
        { text: 'Tipo de periodo', value: 'periodType' },
        // { text: 'Período de reporte', value: 'reportPeriod' },
        { text: 'Última modificación', value: 'lastModification' },
        { text: 'Estatus', value: 'status', width: '10%' }
      ],
      statusOptions: ['En progreso', 'Completado', 'En retraso'],
      items: [
        {
          id: 1,
          location: 'Guadalajara',
          name: 'Alma Rodríguez',
          source: 'Combustible',
          periodType: 'Anual',
          reportPeriod: 'Agosto 24',
          lastModification: '2023-03-31',
          status: 'En retraso'
        },
        {
          id: 2,
          location: 'Guadalajara',
          name: 'Karime Morgan',
          source: 'Combustible',
          periodType: 'Anual',
          reportPeriod: 'Agosto 24',
          lastModification: '2023-03-31',
          status: 'Completado'
        },
        {
          id: 3,
          location: 'Guadalajara',
          name: 'Karime Morgan',
          source: 'Combustible',
          periodType: 'Mensual',
          reportPeriod: 'Agosto 24',
          lastModification: '2023-03-31',
          status: 'Completado'
        },
        {
          id: 4,
          location: 'CDMX',
          name: 'Jose Santiago',
          source: 'Combustible',
          periodType: 'Anual',
          reportPeriod: 'Agosto 24',
          lastModification: '2023-03-31',
          status: 'En retraso'
        }
      ],

      selectedPeriod: null,
      selectedYear: null,
      periods: ['Anual', 'Mensual', 'Trimestral'],
      years: [2024, 2023, 2022, 2021]
    };
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'En progreso':
          return 'orange';
        case 'Completado':
          return 'green';
        case 'En retraso':
          return 'red';
        default:
          return 'grey';
      }
    },
    applyFilters() {
      console.log('Aplicando filtros:', this.selectedPeriod, this.selectedYear);
    }
  }
};
</script>

<style scoped>
.v-data-table {
  margin-top: 1rem;
}

.status-chip {
  min-width: 95px;
  max-width: 95px;
  display: flex;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
